<router>
{
    name: 'Training',
}
</router>
<template lang="pug">
v-container
    v-card(light, flat)
        v-row
            v-col
                TrainingResourcesManager

</template>
<script>

import TrainingResourcesManager from '@/components/devops/trainingresourcesmanager';

export default {
    meta: {
        role: 'customertraining'
    },
    components: {
        TrainingResourcesManager
    },
    methods: {
    }
};
</script>

<style scoped>
</style>
